import { useUiStateStore } from '@/@core/services/uiStateStore';
import { reactive, toRefs } from '@vue/composition-api';
import { useFinanceStore } from '../../services/store';

type FinanceModelState = {
  headers: any[];
  isLoading: boolean;
  showFormDialog: boolean;
  showDeleteDialog: boolean;
  isFormValid: boolean;
  formData: any;
  search: string;
};

const state = reactive<FinanceModelState>({
  headers: [
    {
      text: 'Nama Pembiayaan',
      value: 'name'
    },
    {
      text: 'Deskripsi',
      value: 'description'
    },
    {
      text: '',
      value: 'actions',
      sortable: false,
      width: '1%'
    }
  ],
  isLoading: false,
  showFormDialog: false,
  showDeleteDialog: false,
  isFormValid: false,
  formData: null,
  search: ''
});

export default function useFinanceModel() {
  const store = useFinanceStore();

  const onGetManyFinance = async () => {
    state.isLoading = true;

    try {
      state.isLoading = true;
      await store.getManyFinance();
    } catch (err: any) {
      // useErrorMessageHandler(err)
    } finally {
      state.isLoading = false;
    }
  };

  const onCreateFinance = async (data: any) => {
    state.isLoading = true;

    try {
      await store.postFinance(data);
      useUiStateStore().createSnackbar({
        message: `Pembiayaan ${data.name} berhasil ditambahkan.`
      });

      state.showFormDialog = false;
      await onGetManyFinance();
      state.formData = null;
    } catch (err: any) {
      // useErrorMessageHandler(err)
    } finally {
      state.isLoading = false;
    }
  };

  const onUpdateFinance = async (data: any) => {
    state.isLoading = true;

    try {
      await store.putFinance(data);
      useUiStateStore().createSnackbar({
        message: `Pembiayaan ${data.name} berhasil diubah.`
      });
      state.showFormDialog = false;
      await onGetManyFinance();
      state.formData = null;
    } catch (err: any) {
      // useErrorMessageHandler(err)
    } finally {
      state.isLoading = false;
    }
  };

  const createFinance = () => {
    state.formData = null;
    state.showFormDialog = true;
  };

  const editFinance = (finance: any) => {
    state.formData = { ...finance };
    state.showFormDialog = true;
  };

  const deleteFinance = (finance: any) => {
    state.formData = finance;
    state.showDeleteDialog = true;
  };

  const onDeleteFinance = async () => {
    state.isLoading = true;

    try {
      await store.deleteFinance(state.formData);
      const data: any = state.formData || {};
      useUiStateStore().createSnackbar({
        message: `Pembiayaan ${data.name} berhasil dihapus.`
      });
      state.showDeleteDialog = false;
      state.formData = null;
      await onGetManyFinance();
    } catch (err: any) {
      // useErrorMessageHandler(err)
    } finally {
      state.isLoading = false;
    }
  };

  const onSubmitForm = () => {
    const isEdit = (state.formData as any)?.id;

    if (isEdit) onUpdateFinance(state.formData);
    else onCreateFinance(state.formData);
  };

  return {
    ...toRefs(state),
    store,
    onGetManyFinance,
    onSubmitForm,
    onDeleteFinance,
    deleteFinance,
    editFinance,
    createFinance
  };
}

export { useFinanceModel };
