


















import { isMobile } from '@/@core/helpers/useBreakpoint'
import { defineComponent } from '@vue/composition-api'
import KText from '../typography/KText.vue'

export default defineComponent({
  components: { KText },
    name: 'KTableActionButton',
    props: {
        'text': {
            type: String,
            default: 'Tambah'
        },
        'icon': {
            type: String,
            default: 'mdi-plus'
        },
        'color': {
            type: String,
            default: 'primary'
        },
        'compact-on-mobile': {
            type: Boolean,
            default: false
        },
    },
    setup() {
        //

        return {
            isMobile
        }
    }
})
